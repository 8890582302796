import { inject }                           from 'aurelia-framework';
import { PLATFORM }                         from 'aurelia-pal';
import { BaseFormViewModel }                from 'base-form-view-model';
import { FormSchema }                       from 'modules/management/additional-information/additional-informations/form-schema';
import { AdditionalInformationsRepository } from 'modules/management/additional-information/additional-informations/services/repository';
import { AppContainer }                     from 'resources/services/app-container';

@inject(AppContainer, AdditionalInformationsRepository, FormSchema)
export class ViewAdditionalInformation extends BaseFormViewModel {
    headerTitle = 'form.title.view-record';
    formId      = 'additional-information.additional-informations.view-form';

    alert  = {};
    model  = {};
    schema = {};

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param formSchema
     */
    constructor(appContainer, repository, formSchema) {
        super(appContainer);

        this.repository = repository;
        this.formSchema = formSchema;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return PLATFORM.moduleName('modules/management/additional-information/additional-informations/form.html');
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('additional-information.additional-information-types.index') && super.can([
            'additional-information.additional-informations.manage',
            'additional-information.additional-informations.view',
        ]);
    }

    /**
     * Handles activate event
     */
    async activate(params) {
        super.activate();

        this.initialModel = this.formSchema.model();
        this.model        = this.formSchema.model();

        await this.fetchData(params);

        // Check if lot is closed or validated
        if (this.appContainer.authenticatedUser.isLotClosed) {
            this.alert = this.warningAlertMessage('messages.selected-lot-intervention-is-closed');
        } else if (this.model.is_validated) {
            this.alert = this.infoAlertMessage('form.message.viewing-validated-record');
        }

        this.schema        = this.formSchema.schema(this, true);
        this.lotInfoSchema = this.formSchema.lotInfoSchema(this);
        this.globalSchema  = this.formSchema.globalSchema(this, true);
    }

    /**
     * Fetches data from server
     *
     * @param {id}
     *
     * @returns {Promise.<*>|Promise}
     */
    async fetchData({ id }) {
        const response = await this.repository.find(id);
        this.initialModel.assign(response);
        this.model.assign(response);
    }
}
