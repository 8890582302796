import { DialogService }                    from 'aurelia-dialog';
import { bindable, inject }                 from 'aurelia-framework';
import { I18N }                             from 'aurelia-i18n';
import { PLATFORM }                         from 'aurelia-pal';
import { BaseListViewModel }                from 'base-list-view-model';
import { FilterFormSchema }                 from 'modules/management/additional-information/additional-informations/filter-form-schema';
import { AdditionalInformationsRepository } from 'modules/management/additional-information/additional-informations/services/repository';
import { LotInterventionsRepository }       from 'modules/management/concessions/concessions-tree/lot-interventions/services/repository';
import { InfoDialog }                       from 'resources/elements/html-elements/dialogs/info-dialog';
import { AppContainer }                     from 'resources/services/app-container';
import { Downloader }                       from 'resources/services/downloader';

@inject(AppContainer, AdditionalInformationsRepository, I18N, Downloader, FilterFormSchema, DialogService, LotInterventionsRepository)
export class ListAdditionalInformations extends BaseListViewModel {

    listingId = 'additional-informations-listing';

    @bindable headerTitle    = 'listing.additional-informations.additional-informations';
    @bindable newRecordRoute = 'additional-information.additional-informations.create';
    @bindable repository;
    @bindable datatable;
    @bindable row;

    /**
     * Constructor
     *
     * @param appContainer
     * @param repository
     * @param i18N
     * @param downloader
     * @param filterFormSchema
     * @param dialogService
     * @param lotInterventionsRepository
     */
    constructor(appContainer, repository, i18N, downloader, filterFormSchema, dialogService, lotInterventionsRepository) {
        super(appContainer, lotInterventionsRepository);

        this.repository       = repository;
        this.i18N             = i18N;
        this.downloader       = downloader;
        this.filterFormSchema = filterFormSchema;
        this.dialogService    = dialogService;
    }

    /**
     * Returns view strategy
     *
     * @returns {string}
     */
    getViewStrategy() {
        return this.appContainer.globalConfig.defaultListView;
    }

    /**
     * Handles canActivate event
     */
    canActivate() {
        return this.redirectToSelectLotIntervention('additional-information.additional-informations.index') && super.can([
            'additional-information.additional-informations.manage',
            'additional-information.additional-informations.view',
        ]);
    }

    /**
     * Handles activate event
     */
    activate() {
        super.activate();

        if (this.appContainer.authenticatedUser.isLotClosed) {
            this.newRecordRoute = null;
        }

        this.filterSchema = this.filterFormSchema.schema(this);
        this.filterModel  = this.filterFormSchema.model(this);

        // Default filter by the current lot_intervention_id
        if (!this.filterModel.lot_intervention_id) {
            this.selectedLotInformation          = this.appContainer.authenticatedUser.getAllSelectedLotInformation();
            this.filterModel.lot_intervention_id = this.selectedLotInformation.lot_intervention_id;
        }

        this.defineDatatable();
    }

    /**
     * Defines table columns
     */
    defineDatatable() {
        this.datatable = {
            repository:         this.repository,
            show:               {
                action:  (row) => this.appContainer.router.navigateToRoute('additional-information.additional-informations.view', { id: row.id }),
                visible: (row) => this.appContainer.authenticatedUser.can(['additional-information.additional-informations.manage', 'additional-information.additional-informations.view']),
            },
            edit:               {
                action:  (row) => this.appContainer.router.navigateToRoute('additional-information.additional-informations.edit', { id: row.id }),
                visible: (row) => !row.is_validated && this.appContainer.authenticatedUser.can(['additional-information.additional-informations.manage', 'additional-information.additional-informations.edit']),
            },
            destroy:            {
                action:  (row) => this.repository.destroy(row.id),
                visible: (row) => this.appContainer.authenticatedUser.can(['additional-information.additional-informations.manage', 'additional-information.additional-informations.delete']),
            },
            options:            [
                {
                    label:   'form.button.validate-selected',
                    icon:    'icon-checkmark3',
                    action:  () => this.ensureSelectedRows(() => {
                        this.callThenResponse(this.repository.validateSelected(this.datatable.instance.selectedRows));
                    }),
                    visible: () => this.appContainer.authenticatedUser.can(['additional-information.additional-informations.manage', 'additional-information.additional-informations.validate']),
                },
                {
                    label:   'form.button.invalidate-selected',
                    icon:    'icon-cross',
                    action:  () => this.ensureSelectedRows(() => {
                        this.callThenResponse(this.repository.invalidateSelected(this.datatable.instance.selectedRows));
                    }),
                    visible: () => this.appContainer.authenticatedUser.can(['additional-information.additional-informations.manage', 'additional-information.additional-informations.invalidate']),
                },
                {
                    label:   'form.button.export-to-excel',
                    icon:    'icon-file-excel',
                    action:  () => this.exportListing(this.i18N.tr('form.field.additional-information'), !this.datatable.instance.selectedRows.length ? null : this.datatable.instance.selectedRows),
                    visible: () => this.appContainer.authenticatedUser.can(['additional-information.additional-informations.manage', 'additional-information.additional-informations.view']),
                },
                {
                    label:   'form.button.export-zip',
                    icon:    'icon-file-zip',
                    action:  () => this.exportZip(),
                    visible: () => this.appContainer.authenticatedUser.can(['additional-information.additional-informations.manage', 'additional-information.additional-informations.view']),
                },
            ],
            actions:            [
                {
                    icon:    'icon-cross',
                    action:  (row) => this.callThenResponse(this.repository.invalidate(row.id)),
                    tooltip: 'form.button.invalidate',
                    visible: (row) => row.validatable && this.appContainer.authenticatedUser.can(['additional-information.additional-informations.manage', 'additional-information.additional-informations.invalidate'])
                        && row.is_validated && !this.appContainer.authenticatedUser.isBuilder && this.appContainer.authenticatedUser.inSameLotIntervention(row.lot_intervention_id),
                },
                {
                    icon:    'icon-checkmark3',
                    action:  (row) => this.callThenResponse(this.repository.validate(row.id)),
                    tooltip: 'form.button.validate',
                    visible: (row) => row.validatable && this.appContainer.authenticatedUser.can(['additional-information.additional-informations.manage', 'additional-information.additional-informations.validate'])
                        && !row.is_validated && this.appContainer.authenticatedUser.inSameLotIntervention(row.lot_intervention_id),
                },
                {
                    icon:    'icon-copy3',
                    action:  (row) => this.copyToAnotherLot(row.id, row.lot_intervention_id, 'additional-information.additional-informations.create'),
                    tooltip: 'form.button.duplicate',
                    visible: (row) => this.appContainer.authenticatedUser.can(['additional-information.additional-informations.manage', 'additional-information.additional-informations.create'])
                        && row.is_lot_intervention_related,
                },
            ],
            actionsContextMenu: true,
            selectable:         true,
            destroySelected:    true,
            sorting:            {
                column:    0,
                direction: 'desc',
            },
            columns:            [
                {
                    data:  'number',
                    name:  'additional_informations.number',
                    title: 'form.field.num-registration',
                },
                {
                    data:  'concession_description',
                    name:  'concession_translations.description',
                    title: 'form.field.concession',
                },
                {
                    data:  'highway_designation',
                    name:  'highway_translations.designation',
                    title: 'form.field.highway',
                },
                {
                    data:  'intervention_type_designation',
                    name:  'intervention_type_translations.designation',
                    title: 'form.field.intervention-type',
                },
                {
                    data:  'lot_intervention_designation',
                    name:  'lot_intervention_translations.designation',
                    title: 'form.field.lot-intervention',
                },
                {
                    data:  'type',
                    name:  'additional_information_type_translations.name',
                    title: 'form.field.type',
                },
                {
                    data:  'description',
                    name:  'additional_information_translations.description',
                    title: 'form.field.description',
                },
                {
                    data:  'scope',
                    name:  'additional_informations.scope',
                    title: 'form.field.scope',
                },
                {
                    data:  'standard',
                    name:  'additional_informations.standard',
                    title: 'form.field.standard',
                },
                {
                    data:  'referencing_data',
                    name:  'additional_informations.referencing_data',
                    title: 'form.field.referencing-data',
                },
                {
                    data:  'observations',
                    name:  'additional_informations.observations',
                    title: 'form.field.observations',
                },
                {
                    data:  'entity_name',
                    name:  'entity_translations.name',
                    title: 'form.field.entity',
                },
                {
                    data:   'team_name',
                    name:   'team_translations.name',
                    title:  'form.field.team',
                    hidden: true,
                },
                {
                    data:  'created_by',
                    name:  'user_entity_team_lots.identifier',
                    title: 'form.field.created-by',
                    type:  'userEntityTeamLot',
                },
                {
                    data:            'created_at',
                    name:            'additional_informations.created_at',
                    title:           'form.field.created-at',
                    valueConverters: [
                        {
                            name: 'dateTimeFormat',
                        },
                    ],
                },
                {
                    data:          'is_validated',
                    name:          'is_validated',
                    title:         'form.field.validated',
                    type:          'custom-cell',
                    fullDataModel: 'validator',
                    viewModel:     PLATFORM.moduleName('modules/core/custom-listing-cells/checkmark/index'),
                    orderable:     false,
                    searchable:    false,
                },
                {
                    data:       'file',
                    name:       'file',
                    title:      'form.field.file',
                    type:       'custom-cell',
                    viewModel:  PLATFORM.moduleName('modules/management/additional-information/additional-informations/custom-listing-cells/file/index'),
                    orderable:  false,
                    searchable: false,
                },
            ],
        };
    }

    /**
     * Executes a given action if it is selected at least one row
     *
     * @param action
     * @returns {*}
     */
    ensureSelectedRows(action) {
        if (!this.datatable.instance.selectedRows.length) {
            return this.dialogService.open({
                viewModel: InfoDialog,
                model:     {
                    body:  this.appContainer.i18n.tr('message.select-at-least-one-record'),
                    title: this.appContainer.i18n.tr('text.attention'),
                },
            });
        }

        action();
    }

    /**
     * Call promise then show response notice and reload datatable
     * @param call
     */
    callThenResponse(call) {
        call.then(response => {
            this.appContainer.notifier.responseNotice(response);
            this.datatable.instance.reload();
        });
    }

    /**
     * Export zip
     *
     */
    exportZip() {
        const filterModel = Object.assign(this.filterModel);

        filterModel.selectedRows = this.datatable.instance.selectedRows;

        return this.repository
            .generateZip(filterModel)
            .then(response => {
                response.status === true
                    ? this.appContainer.notifier.successNotice(this.i18N.tr('form.message.will-receive-message-after-file-generated'))
                    : this.appContainer.notifier.dangerNotice(response.message);
            });
    }
}
